import { FC } from "react";

import { IconProps, Icon } from "../icon";

export const MergeIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props} viewBox="0 0 20 20">
      <path d="M4.08338 4.33371C4.45157 4.05757 4.97391 4.13218 5.25005 4.50037L8.00005 8.16704C8.46868 8.79188 9.20216 9.16152 9.98251 9.16698C9.98836 9.16695 9.9942 9.16694 10 9.16694V10.8336C9.9942 10.8336 9.98836 10.8337 9.98251 10.8337C9.20216 10.8391 8.46868 11.2088 8.00005 11.8336L5.25005 15.5003C4.97391 15.8685 4.45157 15.9431 4.08338 15.6669C3.71519 15.3908 3.64057 14.8685 3.91672 14.5003L6.66672 10.8336C6.90639 10.514 7.18762 10.2345 7.49992 10.0003C7.18762 9.76611 6.90639 9.4866 6.66672 9.16704L3.91672 5.50037C3.64057 5.13218 3.71519 4.60985 4.08338 4.33371Z" />
      <path d="M12.7999 8.14053C12.4463 7.84589 12.3986 7.32042 12.6932 6.96686C12.9878 6.6133 13.5133 6.56553 13.8669 6.86016L17.1938 9.35483C17.3807 9.50765 17.5001 9.74006 17.5001 10.0003C17.5001 10.2027 17.4279 10.3882 17.308 10.5325C17.2774 10.5694 17.2435 10.6038 17.2065 10.6353L17.1978 10.6426L13.8669 13.1405C13.5133 13.4352 12.9878 13.3874 12.6932 13.0338C12.3986 12.6803 12.4463 12.1548 12.7999 11.8602L14.365 10.8337L10 10.8336V9.16694L14.365 9.16699L12.7999 8.14053Z" />
    </Icon>
  );
};

export const MergeSVG = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.08338 4.33371C4.45157 4.05757 4.97391 4.13218 5.25005 4.50037L8.00005 8.16704C8.46868 8.79188 9.20216 9.16152 9.98251 9.16698C9.98836 9.16695 9.9942 9.16694 10 9.16694V10.8336C9.9942 10.8336 9.98836 10.8337 9.98251 10.8337C9.20216 10.8391 8.46868 11.2088 8.00005 11.8336L5.25005 15.5003C4.97391 15.8685 4.45157 15.9431 4.08338 15.6669C3.71519 15.3908 3.64057 14.8685 3.91672 14.5003L6.66672 10.8336C6.90639 10.514 7.18762 10.2345 7.49992 10.0003C7.18762 9.76611 6.90639 9.4866 6.66672 9.16704L3.91672 5.50037C3.64057 5.13218 3.71519 4.60985 4.08338 4.33371Z" />
    <path d="M12.7999 8.14053C12.4463 7.84589 12.3986 7.32042 12.6932 6.96686C12.9878 6.6133 13.5133 6.56553 13.8669 6.86016L17.1938 9.35483C17.3807 9.50765 17.5001 9.74006 17.5001 10.0003C17.5001 10.2027 17.4279 10.3882 17.308 10.5325C17.2774 10.5694 17.2435 10.6038 17.2065 10.6353L17.1978 10.6426L13.8669 13.1405C13.5133 13.4352 12.9878 13.3874 12.6932 13.0338C12.3986 12.6803 12.4463 12.1548 12.7999 11.8602L14.365 10.8337L10 10.8336V9.16694L14.365 9.16699L12.7999 8.14053Z" />
  </svg>
);
