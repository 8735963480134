import { useMemo, useState, FC, useEffect, useRef, useCallback } from "react";

import { useDisclosure, Popover, PopoverContent, PopoverTrigger, Portal } from "@chakra-ui/react";
import { ArrowPathIcon, Cog6ToothIcon, ExclamationTriangleIcon, PencilIcon } from "@heroicons/react/24/outline";
import {
  Box,
  Row,
  Column,
  Switch,
  Tooltip,
  useToast,
  SectionHeading,
  Pill,
  Text,
  IconButton,
  SearchInput,
  Select,
  FormField,
  TextInput,
  Button,
  Menu,
  MenuList,
  MenuItem,
  MenuIconButton,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";

import {
  ObjectColumnFragment,
  ResourcePermissionGrant,
  useUpdateModelColumnMutation,
  useUpdateModelColumnsMutation,
} from "src/graphql";
import useHasPermission from "src/hooks/use-has-permission";
import { ColumnDateTypes, ColumnType } from "src/types/visual";
import { Wrap } from "src/ui/box";
import { BooleanIcon, CalendarIcon, ClockIcon, NullIcon, NumberIcon, QuestionIcon, StringIcon } from "src/ui/icons";
import { SuggestionsIcon } from "src/ui/icons/new-icons";
import { Pagination, Table } from "src/ui/table";
import { getSearchRegExp } from "src/utils/string";
import { formatDate } from "src/utils/time";

import { Permission } from "../permission";

type Props = {
  source?: any;
  modelId: string;
  columns: ObjectColumnFragment[] | undefined;
};

export enum RefreshIntervals {
  Hourly = 3600,
  Daily = 86400,
  Weekly = 604800,
  BiWeekly = 1209600,
  Monthly = 2419200,
  ManualOnly = -1,
}

const REFRESH_OPTIONS = [
  {
    label: "Hourly",
    value: RefreshIntervals.Hourly,
  },
  {
    label: "Daily",
    value: RefreshIntervals.Daily,
  },
  {
    label: "Weekly",
    value: RefreshIntervals.Weekly,
  },
  {
    label: "Bi-weekly",
    value: RefreshIntervals.BiWeekly,
  },
  {
    label: "Monthly",
    value: RefreshIntervals.Monthly,
  },
  {
    label: "Manual Only",
    value: RefreshIntervals.ManualOnly,
  },
];

const SUPPORTED_SUGGESTION_SOURCES = ["postgres", "bigquery", "snowflake", "athena", "redshift", "databricks", "sample-data"];
const SUPPORTED_JSON_ARRAY_SOURCES = ["postgres", "bigquery", "snowflake"];

// Represents the supported casts for each source type that should be available in the UI.
// TODO: expand support for casting as backend support increases
const SUPPORTED_COLUMN_CASTS = {
  snowflake: {
    [ColumnType.String]: [
      { label: "String", value: ColumnType.String, icon: StringIcon },
      { label: "Number", value: ColumnType.Number, icon: NumberIcon },
      { label: "Timestamp", value: ColumnType.Timestamp, icon: ClockIcon },
    ],
  },
  redshift: {
    [ColumnType.String]: [
      { label: "String", value: ColumnType.String, icon: StringIcon },
      { label: "Number", value: ColumnType.Number, icon: NumberIcon },
    ],
    [ColumnType.Timestamp]: [
      { label: "Timestamp", value: ColumnType.Timestamp, icon: ClockIcon },
      { label: "Date", value: ColumnType.Date, icon: CalendarIcon },
    ],
  },
};

const JSON_ARRAY_OPTIONS = [
  { label: "JSON Array (Strings)", value: ColumnType.JsonArrayStrings, icon: StringIcon },
  { label: "JSON Array (Numbers)", value: ColumnType.JsonArrayNumbers, icon: NumberIcon },
];

const ALL_COLUMN_OPTIONS = [
  { label: "String", value: ColumnType.String, icon: StringIcon },
  { label: "Number", value: ColumnType.Number, icon: NumberIcon },
  { label: "Timestamp", value: ColumnType.Timestamp, icon: ClockIcon },
  { label: "Date", value: ColumnType.Date, icon: CalendarIcon },
  {
    label: "Boolean",
    value: ColumnType.Boolean,
    icon: BooleanIcon,
  },
  {
    label: "Unknown",
    value: ColumnType.Unknown,
    icon: QuestionIcon,
  },
  {
    label: "Null",
    value: ColumnType.Null,
    icon: NullIcon,
  },
];

const limitError = "Too many distinct values. Only the 10,000 most frequent values will be shown as suggestions.";

const ROW_LIMIT = 20;

export const ColumnSettings: FC<Readonly<Props>> = ({ modelId, source, columns }) => {
  const { appExposeCaseSensitiveColumnSetting } = useFlags();

  const { toast } = useToast();
  const { hasPermission: hasUpdatePermission } = useHasPermission([
    { resource: "audience_schema", grants: [ResourcePermissionGrant.Update] },
  ]);

  const [search, setSearch] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(0);

  const [objectColumns, setObjectColumns] = useState(columns);

  useEffect(() => {
    setObjectColumns(columns);
  }, [columns]);

  const refreshInterval = objectColumns?.find(({ top_k_sync_interval }) => Boolean(top_k_sync_interval))?.top_k_sync_interval;
  const refreshing = Boolean(
    objectColumns?.find(({ trigger_top_k_sync, top_k_enabled }) => top_k_enabled && trigger_top_k_sync),
  );
  const [currentRefreshInterval, setCurrentRefreshInterval] = useState<number>(
    refreshInterval !== undefined && refreshInterval !== null ? refreshInterval : RefreshIntervals.ManualOnly,
  );
  const suggestionsDisabled = !SUPPORTED_SUGGESTION_SOURCES.includes(source?.type);
  const jsonArraysEnabled = SUPPORTED_JSON_ARRAY_SOURCES.includes(source?.type);
  const columnCasts = source?.type in SUPPORTED_COLUMN_CASTS ? SUPPORTED_COLUMN_CASTS[source?.type] : null;

  const { mutateAsync: updateColumn } = useUpdateModelColumnMutation({
    onSuccess: (data, variables) => {
      setObjectColumns(data?.update_model_columns_by_pk?.model?.columns);
      toast({
        id: "update-column",
        title: `Updated column settings for ${variables.name}`,
        variant: "success",
      });
    },
    onError: (err, variables) => {
      Sentry.captureException(err);
      toast({
        id: "update-column",
        title: `Couldn't update column settings for ${variables.name}`,
        variant: "error",
      });
    },
  });

  const { mutateAsync: updateColumns } = useUpdateModelColumnsMutation({
    onSuccess: (data) => {
      setObjectColumns(data?.update_model_columns?.returning?.[0]?.model?.columns);
      toast({
        id: "update-columns",
        title: "Updated column settings",
        variant: "success",
      });
    },
    onError: (err) => {
      Sentry.captureException(err);
      toast({
        id: "update-columns",
        title: "Couldn't update column settings",
        variant: "error",
      });
    },
  });

  const toggleAllSuggestions = useCallback(
    async (enable: boolean) => {
      if (suggestionsDisabled) return;

      // Certain columns can't have their suggestions toggled, so we need to exclude them
      const untoggleableColumns: string[] = (objectColumns ?? [])
        .filter(
          (column) =>
            ColumnDateTypes.includes(column.type as ColumnType) || (enable && (column.disable || column.disable_preview)),
        )
        .map((column) => column.name);

      await updateColumns({
        id: modelId,
        input: {
          top_k_enabled: enable,
        },
        except: untoggleableColumns,
      });
    },
    [suggestionsDisabled, objectColumns, updateColumns],
  );

  const tableColumns = [
    {
      name: "Enabled",
      max: "max-content",
      cell: ({ disable, name }) => {
        return (
          <Row flex={1} justify="center">
            <Tooltip isDisabled={hasUpdatePermission} message="You do not have permission to perform this action">
              <Switch
                size="sm"
                isChecked={!disable}
                isDisabled={!hasUpdatePermission}
                onChange={(value) => {
                  updateColumn({ id: modelId, name, input: { disable: !value } });
                }}
              />
            </Tooltip>
          </Row>
        );
      },
    },
    {
      name: "Name",
      cell: ({ alias, name }) => {
        const ref = useRef<HTMLInputElement | null>(null);
        const { isOpen, onToggle, onClose } = useDisclosure();
        const [isSaving, setIsSaving] = useState(false);
        const [isDeleting, setIsDeleting] = useState(false);
        const [value, setValue] = useState(alias ?? "");

        return (
          <Row
            overflow="hidden"
            gap={2}
            align="center"
            sx={{ svg: { visibility: isOpen ? "visible" : "hidden" } }}
            _hover={{ svg: { visibility: "visible" } }}
          >
            {alias ? (
              <Column overflow="hidden">
                <Text isTruncated fontWeight="medium">
                  {alias}
                </Text>
                <Text isTruncated color="text.secondary">
                  {name}
                </Text>
              </Column>
            ) : (
              <Text isTruncated fontWeight="medium">
                {name}
              </Text>
            )}
            <Popover
              returnFocusOnClose={false}
              isOpen={isOpen}
              onClose={onClose}
              placement="right"
              closeOnBlur={true}
              initialFocusRef={ref}
              isLazy
            >
              <PopoverTrigger>
                <IconButton icon={PencilIcon} aria-label="Edit name" size="sm" onClick={onToggle} />
              </PopoverTrigger>
              <Portal>
                <PopoverContent>
                  <Column
                    gap={3}
                    p={3}
                    as="form"
                    onSubmit={async (event) => {
                      event.preventDefault();
                      setIsSaving(true);
                      await updateColumn({ id: modelId, name, input: { alias: value } });
                      setIsSaving(false);
                      onClose();
                    }}
                  >
                    <FormField label="Set an alias" tip="Replace the column name with an alias">
                      <TextInput
                        ref={ref}
                        value={value}
                        onChange={(event) => setValue(event.target.value)}
                        width="100%"
                        placeholder="Enter an alias..."
                      />
                    </FormField>
                    <Row justify="space-between" gap={4}>
                      <Row gap={2}>
                        <Tooltip isDisabled={hasUpdatePermission} message="You do not have permission to perform this action">
                          <Button
                            type="submit"
                            isDisabled={!hasUpdatePermission || !value || value === alias}
                            variant="primary"
                            isLoading={isSaving}
                          >
                            Save
                          </Button>
                        </Tooltip>
                        <Button
                          onClick={() => {
                            setValue(alias ?? "");
                            onClose();
                          }}
                        >
                          Cancel
                        </Button>
                      </Row>

                      {alias && (
                        <Button
                          isDisabled={!hasUpdatePermission}
                          isLoading={isDeleting}
                          variant="warning"
                          onClick={async () => {
                            setIsDeleting(true);
                            await updateColumn({ id: modelId, name, input: { alias: "" } });
                            setValue("");
                            setIsDeleting(false);
                          }}
                        >
                          Remove alias
                        </Button>
                      )}
                    </Row>
                  </Column>
                </PopoverContent>
              </Portal>
            </Popover>
          </Row>
        );
      },
    },
    {
      name: "Type",
      max: "max-content",
      cell: ({ name, type: warehouseType, custom_type: customType, disable }) => {
        const type = customType ?? warehouseType;
        const isValidJsonArrayColumnType = [ColumnType.Json, ColumnType.JsonArrayStrings, ColumnType.JsonArrayNumbers].includes(
          type,
        );
        const isColumnCasts = columnCasts && warehouseType in columnCasts;
        const isJsonArray = jsonArraysEnabled && isValidJsonArrayColumnType;
        const notSupported = !isColumnCasts && !isJsonArray;
        return (
          <Tooltip
            isDisabled={hasUpdatePermission || !notSupported}
            message={
              !hasUpdatePermission
                ? "You do not have permission to perform this action"
                : `Type conversion is not supported for ${warehouseType} columns`
            }
          >
            <Box sx={{ span: { display: "none" } }}>
              <Select
                width="auto"
                optionAccessory={(option: any) => ({
                  type: "icon",
                  icon: option.icon,
                })}
                isDisabled={disable || !hasUpdatePermission || notSupported}
                options={isColumnCasts ? columnCasts[warehouseType] : isJsonArray ? JSON_ARRAY_OPTIONS : ALL_COLUMN_OPTIONS}
                value={isJsonArray ? type === ColumnType.Json || undefined : type}
                onChange={(value) => {
                  updateColumn({ id: modelId, name, input: { custom_type: value } });
                }}
              />
            </Box>
          </Tooltip>
        );
      },
    },
    appExposeCaseSensitiveColumnSetting && {
      name: "Aa",
      header: () => (
        <Row justify="center" flex={1}>
          <Tooltip message="Case sensitive field">
            <Box sx={{ textTransform: "initial !important" }}>Aa</Box>
          </Tooltip>
        </Row>
      ),
      max: "max-content",
      cell: ({ case_sensitive, name, type, disable }) => {
        // Don't show toggle for ColumnType.JsonArrayStrings because backend logic doesn't handle case insensitivity
        if (type === ColumnType.String) {
          return (
            <Tooltip isDisabled={hasUpdatePermission} message="You do not have permission to perform this action">
              <Switch
                size="sm"
                isChecked={case_sensitive}
                isDisabled={!hasUpdatePermission || disable}
                onChange={(value) => {
                  updateColumn({ id: modelId, name, input: { case_sensitive: value } });
                }}
              />
            </Tooltip>
          );
        }
        return (
          <Row justify="center" flex={1}>
            <Tooltip message="Only supported for string columns">--</Tooltip>
          </Row>
        );
      },
    },

    {
      name: "***",
      header: () => (
        <Row justify="center" flex={1}>
          <Tooltip message="Redacted field">***</Tooltip>
        </Row>
      ),
      max: "max-content",
      cell: ({ disable_preview, disable, name }) => {
        return (
          <Tooltip isDisabled={hasUpdatePermission} message="You do not have permission to perform this action">
            <Switch
              size="sm"
              isChecked={disable_preview}
              isDisabled={!hasUpdatePermission || disable}
              onChange={(value) => {
                updateColumn({
                  id: modelId,
                  name,
                  input: {
                    disable_preview: value,
                    top_k_enabled: value ? false : undefined,
                  },
                });
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      name: "Suggestions",
      header: () => (
        <Row pl={1}>
          <Tooltip message="Suggestions will cache common column values for Audience builder.">
            <SuggestionsIcon />
          </Tooltip>
        </Row>
      ),
      max: "max-content",
      cell: ({
        top_k_enabled: enabled,
        name,
        last_top_k_sync_timestamp: timestamp,
        top_k_error: error,
        disable,
        disable_preview,
        type,
      }) => {
        const isDateType = ColumnDateTypes.includes(type);
        const isLimitError = error?.startsWith("number of distinct values exceeds limit");

        if (isDateType) {
          return (
            <Row pl={1} flex={1}>
              <Tooltip message="Not supported for date type columns">
                <Text color="text.secondary" fontWeight="semibold">
                  --
                </Text>
              </Tooltip>
            </Row>
          );
        }

        if (suggestionsDisabled) {
          return (
            <Row pl={1} flex={1}>
              <Tooltip message="Not supported for this source">
                <Text color="text.secondary" fontWeight="semibold">
                  --
                </Text>
              </Tooltip>
            </Row>
          );
        }

        return (
          <Row align="center" justify="center" flex={1} gap={2}>
            <Tooltip
              isDisabled={hasUpdatePermission && !(enabled && timestamp) && !disable_preview}
              message={
                !hasUpdatePermission
                  ? "You do not have permission to perform this action"
                  : disable_preview
                  ? "Suggestions are not available for redacted columns"
                  : `Last updated: ${formatDate(timestamp)}`
              }
            >
              <Switch
                size="sm"
                isChecked={enabled}
                isDisabled={!hasUpdatePermission || disable || disable_preview}
                onChange={(value) => {
                  updateColumn({ id: modelId, name, input: { top_k_enabled: value } });
                }}
              />
            </Tooltip>
            <Box width="20px" height="20px">
              {error && !disable && enabled && (
                <Tooltip message={isLimitError ? limitError : error}>
                  <ExclamationTriangleIcon
                    color={isLimitError ? "var(--chakra-colors-warning-border)" : "var(--chakra-colors-danger-base)"}
                    width="20px"
                    height="20px"
                    strokeWidth="2px"
                  />
                </Tooltip>
              )}
            </Box>
          </Row>
        );
      },
    },
  ].filter(Boolean);

  const filteredColumns = useMemo(() => {
    const regex = getSearchRegExp(search, "i");
    return (objectColumns ?? []).filter(({ name, alias }) => regex.test(name) || regex.test(alias ?? ""));
  }, [search, objectColumns]);

  return (
    <>
      <Row mb={4} width="100%">
        <Wrap spacing={2} sx={{ alignItems: "center", justifyContent: "space-between", flexWrap: "wrap", flex: 1 }}>
          <Row align="center" gap={4} flex={1}>
            <Row align="center" gap={2}>
              <SectionHeading>Columns</SectionHeading>
              <Pill>{objectColumns?.length}</Pill>
            </Row>
            <SearchInput
              width="100%"
              placeholder="Search columns..."
              value={search}
              onChange={(event) => setSearch(event.target.value)}
            />
          </Row>
          <Row align="center" justify="flex-end" flexShrink={0} gap={2} whiteSpace="nowrap">
            <Text fontWeight="medium">Refresh interval</Text>
            <Tooltip isDisabled={hasUpdatePermission} message="You do not have permission to perform this action">
              <Select
                width="auto"
                isDisabled={!hasUpdatePermission}
                options={REFRESH_OPTIONS}
                placeholder="Select an interval..."
                value={currentRefreshInterval}
                onChange={(value) => {
                  if (value) {
                    setCurrentRefreshInterval(value);
                    updateColumns({
                      id: modelId,
                      input: {
                        top_k_sync_interval: value,
                      },
                    });
                  }
                }}
              />
            </Tooltip>

            <Permission>
              <Tooltip message="Refresh all suggestions">
                <IconButton
                  aria-label="Refresh suggestions"
                  variant="secondary"
                  isLoading={refreshing}
                  onClick={() => {
                    updateColumns({
                      id: modelId,
                      input: {
                        trigger_top_k_sync: true,
                      },
                    });
                  }}
                  icon={ArrowPathIcon}
                />
              </Tooltip>
            </Permission>

            <Permission>
              <Menu>
                <MenuIconButton aria-label="Bulk column actions" icon={Cog6ToothIcon} variant="secondary" />
                <MenuList>
                  <MenuItem
                    isDisabled={
                      !hasUpdatePermission ||
                      suggestionsDisabled ||
                      (objectColumns ?? []).every((column) => column.top_k_enabled)
                    }
                    onClick={() => toggleAllSuggestions(true)}
                  >
                    Turn all suggestions on
                  </MenuItem>
                  <MenuItem
                    isDisabled={
                      !hasUpdatePermission ||
                      suggestionsDisabled ||
                      (objectColumns ?? []).every((column) => !column.top_k_enabled)
                    }
                    onClick={() => toggleAllSuggestions(false)}
                  >
                    Turn all suggestions off
                  </MenuItem>
                </MenuList>
              </Menu>
            </Permission>
          </Row>
        </Wrap>
      </Row>

      <Table
        columns={tableColumns}
        data={filteredColumns.slice(0 + currentPage * ROW_LIMIT, currentPage * ROW_LIMIT + ROW_LIMIT)}
        primaryKey="name"
        rowHeight="50px"
      />
      <Row justify="flex-end" mt={4} px={4}>
        <Pagination
          count={filteredColumns.length}
          label="columns"
          page={currentPage}
          rowsPerPage={ROW_LIMIT}
          setPage={setCurrentPage}
        />
      </Row>
    </>
  );
};
